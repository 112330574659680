body {
  min-height: 100vh;
  background: #ffffff;
}

.max-w-124 {
  max-width: 31rem;
}

.trend-value {
  height: 27px;
  background: rgba(108, 237, 192, 0.3);
}

.trend-value.negative {
  background: rgba(237, 108, 192, 0.3);
}

.faq::before {
  content: '';
  position: absolute;
  top: -75px;
  left: 0;
  width: 100%;
  height: 150px;
  background: white;
  border-radius: 0 0 50% 50%;
}

.slick-next,
.slick-prev {
  width: 40px;
  height: 40px;
  filter: drop-shadow(-12px 12px 20px rgba(158, 158, 158, 0.25));
  z-index: 1;
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.slick-next {
  right: -60px;
}

.slick-prev {
  left: -60px;
}

@media all and (max-width: 1023px) {
  .slick-next,
  .slick-prev {
    width: 20px;
    height: 20px;
  }
  .slick-prev {
    left: -30px;
  }
  .slick-next {
    right: -30px;
  }
}

#ourteam .slick-next {
  right: 0;
  width: 40px;
  height: 40px;
}
#ourteam .slick-prev {
  left: 0;
  width: 40px;
  height: 40px;
}
#ourteam .slick-slide {
  padding-top: 0;
  padding-bottom: 60px;
  transition: all 0.5s ease;
}
#ourteam .slick-current {
  padding-top: 60px;
  padding-bottom: 0;
  transition: all 0.5s ease;
}
#ourteam .slick-center >div >div >div {
  box-shadow: 20px 20px 50px rgba(191, 191, 191, 0.25);
  border-color: transparent;
}
#ourteam .slick-center p {
  color: #29ABB5;
}

.slick-dots {
  bottom: -40px;
}
#ourteam .slick-dots {
  bottom: -80px;
}

.slick-dots li {
  width: 25px;
  height: 12px;
}

.slick-dots li button {
  background: #e0e0e0;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  margin: auto;
}

.slick-dots li button:before {
  display: none;
}

.slick-dots li.slick-active button {
  background: #29abb5;
  width: 25px;
}

.featured-on-item-wrapper img {
  transition: all 200ms ease-in-out;
  filter: invert(0.5);
  opacity: 0.63;
}
.featured-on-item-wrapper .featured-on-item-title {
  transition: all 200ms ease-in-out;
  clip-path: inset(0% 50%);
  /* opacity: 0; */
}
.featured-on-item-wrapper:hover img {
  filter: none;
  opacity: 1;
}
.featured-on-item-wrapper:hover .featured-on-item-title {
  clip-path: inset(0% 0%);
  /* opacity: 1; */
}
